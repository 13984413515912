import React from 'react'
import { graphql } from 'gatsby'

import { Bio } from '../components/layout/Bio'
import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { ItemsList } from '../components/page/ItemsList'
import { BlogItem } from '../components/page/BlogItem'
import { ProjectItem } from '../components/page/ProjectItem'
import { H2 } from '../components/styled/H2'
import { PageLink } from '../components/styled/PageLink'

function BlogIndex({
  data: {
    allPosts: { edges: posts },
    allProjects: { edges: projects },
  },
  location,
}) {
  return (
    <Layout bigTitle={location.pathname === `${__PATH_PREFIX__}/`}>
      <SEO
        title="Front page"
      />
      <Bio />
      <ItemsList
        header={<H2>Recent Blog Posts</H2>}
        items={posts}
        component={BlogItem}
        after={<PageLink to={'/blog'}>Se all posts</PageLink>}
      />
      <ItemsList
        header={<H2>My Projects</H2>}
        items={projects}
        component={ProjectItem}
        after={<PageLink to={'/projects'}>Se all projects</PageLink>}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          body: _rawBody
          slug {
            current
          }
          publishedAt
          title
          author {
            name
          }
          categories {
            title
          }
        }
      }
    }
    allProjects: allSanityProject(
      sort: { fields: [creationDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          description: _rawDescription
          slug {
            current
          }
          creationDate
          title
          authors {
            name
          }
          url
          technologies {
            title
          }
        }
      }
    }
  }
`
